import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [file, setFileState] = useState(undefined);
  const [kind, setKindState] = useState(undefined);
  const [country, setCountryState] = useState(undefined);

  const setFile = (e) => {
    var file = e.target.files[0];
    setFileState(file);
  };

  const setKind = (e) => {
    setKindState(e.target.value);
  };

  const setCountry = (e) => {
    setCountryState(e.target.value);
  };

  const reset = () => {
    setError(false);
    setSuccess(false);
    setResponse(null);
    setFileState(undefined);
    setKindState(undefined);
    setCountryState(undefined);
  };

  const processFile = (e) => {
    setIsLoading(true);

    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("kind", kind);
    formdata.append("country", country);

    axios({
      method: "post",
      url: process.env.REACT_APP_API_PATH,
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        setIsLoading(false);
        setResponse(response);
        setSuccess(true);
      })
      .catch((error, response) => {
        setIsLoading(false);
        setResponse(null);
        setError(true);
        if (
          error.response &&
          error.response.data.error &&
          error.response.status === 400
        ) {
          setResponse(error.response);
        }
      });
  };

  return (
    <div className="App d-flex">
      <div className="container">
        <h1 className="text-center pt-4">Fundación Capital</h1>
        <h3 className="text-center">Procesador de archivos</h3>

        <div className="row h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="card py-5 px-2 px-md-5">
            {!success && (
              <>
                <div className="form-group">
                  <label htmlFor="compressed-file" className="mb-4">
                    Selecciona un archivo <em>.zip</em> de tu computadora
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => setFile(e)}
                    type="file"
                    id="compressed-file"
                    name="compressed-file"
                    accept="image/png, application/zip"
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="kindDropdown">Tipo de reporte</label>
                  <select
                    className="form-control"
                    id="kindDropdown"
                    onChange={(e) => setKind(e)}
                  >
                    <option selected disabled>
                      Selecciona una opción
                    </option>
                    <option value="basic">Básico</option>
                    <option value="full">Completo</option>
                  </select>
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="countryDropdown">País</label>
                  <select
                    className="form-control"
                    id="countryDropdown"
                    onChange={(e) => setCountry(e)}
                  >
                    <option selected disabled>
                      Selecciona un país
                    </option>
                    <option value="honduras">Honduras</option>
                    <option value="perú">Perú</option>
                  </select>
                </div>

                <button
                  className="btn btn-primary mt-4"
                  disabled={!file || !kind || !country || isLoading}
                  onClick={() => processFile()}
                >
                  {isLoading ? "Procesando..." : "Procesar"}
                </button>
              </>
            )}

            {error && !response && (
              <div className="alert alert-danger mt-5" role="alert">
                Ocurrió un error. Intenta de nuevo más tarde.
              </div>
            )}

            {error && response && (
              <div className="alert alert-danger mt-5" role="alert">
                {response.data.error === "There is not file" ||
                response.data.error === "No selected file"
                  ? "Debe de seleccionar un archivo para procesar"
                  : null}

                {response.data.error === "Wrong file or invalid extension"
                  ? "Archivo inválido o extensión inválida"
                  : null}
              </div>
            )}

            {success && (
              <div className="d-flex flex-column mt-3">
                <p className="d-block">
                  <strong>Generated File:</strong>
                </p>
                <p className="d-block">{response.data.filename}</p>
                <a
                  href={response.data.filepath}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  className="btn btn-primary btn-block mt-3"
                >
                  Descargar
                </a>

                <button
                  onClick={() => reset()}
                  className="btn btn-secondary btn-block mt-3"
                >
                  Procesar otro archivo
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
